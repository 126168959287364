const initialState = {
  colors:[],
  filterField:'',
  filters: '',
  table:'',
  type:''
};
const printImageReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'UPDATE_VALUES':
      return {
        ...action.value
      }
    default: 
      return state;
  }
}

export default printImageReducer;