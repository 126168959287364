import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {loadCities, getGeographytypeOptions} from '../services/cityService';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import { _MapContext } from 'react-map-gl';

const CitiesDropdown = ({options, changeCity, updateState}) => {

  /// PAUSE BECAUSE RICK CALL WITH OTHER PROYECT: 
  /// I was doing, on select city, call geography types and set geography selected zip 
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownGeoOpen, setDropdownGeoOpen] = useState(false);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [geographyTypes, setGeographyTypes] = useState([]);
  const [selectedGeoType, setSelectedGeotype] = useState('Zip Code');
  const showCity = ()=> {
    let name = {name:'Select City' };
    if(options) {
      name = cities.find((e)=> e.value == options.city);
    }
    if( ! name ) {
      name = { name:'Select City'}
    } 
    return name.name;
  }
  const selectACity = (city) => {
    setSelectedCity(city);
    changeCity(city.value);
  }
  useEffect(()=>{
    const fetchGeoTypes = async ()=>{
      let geotypes = await getGeographytypeOptions(selectedCity.value);
      setGeographyTypes(geotypes);
    } 
    if(selectedCity) {
      fetchGeoTypes();
    }
  },[selectedCity])
  useEffect(()=>{
    const load = async () => {
      let loadedcities = await loadCities();
      let newcities = [{
        name: 'Select City',
        value: ''
      }];
      setSelectedCity({name: 'Select City', value: ''});
      loadedcities.sort((a, b) => {
        return a.dropdown.localeCompare(b.dropdown);
      });
      for (let i = 0; i < loadedcities.length; i++) {
        newcities.push({
          name: loadedcities[i].dropdown,
          value: loadedcities[i].city
        });
      }
      setCities(newcities);
    }
    load();
  },[]);
  useEffect(()=>{
    if(options.city != '') {
      // setSelectedCity(options.city);
        if(cities.length > 0) {
          let _city = cities.find((city, index)=>{
            // if(city.value === options.city) {
            //   return true;
            // }
            let mycity = options.city.replace(/ /g, '');
            mycity = mycity.toLowerCase();
            if ((mycity.includes(city.value) || city.value.includes(mycity)) && city.value != '' ) {
              return true;  
            }
          });
          setSelectedCity(_city);
      }
    }
  },[options.city, cities]);
  const changeGeoTypes = (pos) => {
    let geoType = options.geographytype['geographytype'][pos];
    const newOpt = {...options};
    const index = newOpt.geographytype['geographytype'].findIndex( (geo) => { return geo ==  geoType });
    const selGeoType = newOpt.geographytype['labels'][index];
    setSelectedGeotype(selGeoType);
    newOpt.geographytype['selected'] = geoType;
    if (newOpt.geographytype['selected'] === 'communityarea' || newOpt.geographytype['selected'] === 'ward') {
      newOpt.datacomparison['selected'] = 'none';
    } 
    newOpt.datatype='prevalence';
    updateState(newOpt);
  }
  return (
    <>
    <Dropdown className="btn-group ml-2" isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
      <DropdownToggle caret>
       <small> {showCity()}</small>
        <i className="mdi mdi-menu-down icon-lg "></i>
      </DropdownToggle>
      <DropdownMenu>
        {
          cities.map((city) => (
            city.value && 
            <DropdownItem key={ 'di-' + city.value } onClick={() => selectACity(city)}>
                   {city.name}
            </DropdownItem>
          ))
        }
      </DropdownMenu>
    </Dropdown>
    {options.city &&
    <Dropdown className={"btn-group ml-2 "+( geographyTypes.length <= 1 ? 'disabled':'')} isOpen={dropdownGeoOpen} toggle={() => setDropdownGeoOpen(!dropdownGeoOpen)}>
    <DropdownToggle caret>
      <small>{selectedGeoType}</small>
      <i className="mdi mdi-menu-down icon-lg "></i>
    </DropdownToggle>
    <DropdownMenu>
      {
        (options.city && options.city != '') && 
        options.geographytype['labels'].map((geo,index) => (
          geographyTypes.includes(options.geographytype['geographytype'][index]) && 
          <DropdownItem key={ 'di-' + geo} onClick={()=>changeGeoTypes(index)}>
            <small>{geo}</small>
          </DropdownItem>
        ))
      }
    </DropdownMenu>
  </Dropdown>}
  </>
  )

}

const mapStateToProps = (state) => {
  return {
    options: state.options
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeCity: (city) => {
      dispatch({ type: 'SELECT_CITY', key:'city', city });
    },
    updateState: (options) => {
      dispatch({ type: 'REPLACE_OPTIONS_UPDATE', options})
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CitiesDropdown)