// export let latitude = 0 ;
// export let longitud = 0;
// export let zoom = 0;
// export let nw = [];
// export let se = [];

export const printService = {
  latitude:38.13226557835132 ,
  longitud:-96.00000000000003,
  zoom:3.3,
  // longitud, latitud 
  nw:[-147.50743101307887, 51.01879849251501],
  // longitud, latitud 
  se:[-44.49256898692226, 22.499768446340557]
}
