import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
function CustomRoute({ component: Component, ...rest }) {
  const { path } = rest;
  const { type } = rest;
  const { mapt } = rest;
  return (
    <Route {...rest} render={
      (props) => {
            return (
              <div>
                <Component type={type} mapt={mapt}/>
              </div>
            );
      }
    } />
  )
}

export default CustomRoute;
