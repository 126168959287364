import { combineReducers } from 'redux';
import optionsReducer from './optionsReducer';
import dcReducer from './dcReducer';
import loaderReducer from './loaderReducer';
import downloadReducer from './downloadReducer';
import printReducer from './printReducer';
import printImageReducer from './printImageReducer';
import overlaysReducer from './overlaysReducer';
import arrowReducer from './arrowReducer';
import drawReducer from './drawReducer';
export default combineReducers({
  loaderOptions: loaderReducer,
  options: optionsReducer,
  dcoptions: dcReducer,
  dataValues:  downloadReducer,
  printValues: printReducer,
  printImage: printImageReducer,
  overlays: overlaysReducer,
  arrowValue: arrowReducer,
  drawState: drawReducer
});
