const initialState = {
  open: false,
  basemap: true,
  resolution: 'Normal',
  area: [],
  footer: true,
  title: true,
  legend: true,
  hideMore: true,
  states: [],
  statesInfo: [],
  basemapType: 'cj9ogyubd4ebv2rrr3nibqotp',
  bounds: []
};
const printReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'CHANGE_AREA':
      return {
        ...state, 
        ['area']: [action.value]
      }
    case 'CHANGE_BASEMAP':
      return {
        ...state,
        ['basemapType']: action.value
      }
    case 'CHANGE_BOUNDS': 
      return {
        ...state, 
        ['bounds']:action.value
      }
    default: 
      return state;
  }
}

export default printReducer;