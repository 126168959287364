import axios from 'axios';
import {SERVER, CARTO} from '../settings';
import {environment} from '../environments/environment';

const SERVER_URL = SERVER.BASE_URL;
let lastFilterQuery = null;
let filterCached = null;



export async function getHcsFromCarto() {
  const query = CARTO.GET_QUERY(`SELECT * FROM ${CARTO.HCS_TABLE}`, true);
  return axios.get(query);
}
export async function  getHousingFromCarto() {
  const query = CARTO.GET_QUERY(`SELECT * FROM ${CARTO.HOUSING_TABLE}`, true);
  return axios.get(query);
}
export async function  getHivFromCarto() {
  const query = CARTO.GET_QUERY(`SELECT * FROM ${CARTO.HIV_TABLE}`, true);
  return axios.get(query);
}
export async function  getRyanFromCarto() {
  const query = CARTO.GET_QUERY(`SELECT * FROM ${CARTO.RYAN_TABLE}`, true);
  return axios.get(query);
}
export async function  getPrepFromCarto() {
  const query = CARTO.GET_QUERY(`SELECT * FROM ${CARTO.PREP_TABLE}`, true);
  return axios.get(query);
}
export async function  getGeoJsonFromCarto() {
  const query = CARTO.GET_QUERY('SELECT * FROM ' + CARTO.TABLE, true, true);
  return axios.get(query);
}