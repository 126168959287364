import { CARTO } from '../settings';

let options = {
  hivTesting: { table: CARTO.HIV_TABLE, imgSrc: 'https://i.imgur.com/Pv3ej94.png', fill: '#FF0000' },
  prep: { table: CARTO.PREP_TABLE, imgSrc: 'https://i.imgur.com/Ip14hwu.png', fill: '#00FF00' },
  hivRyanwhite: { table: CARTO.RYAN_TABLE, imgSrc: 'https://i.imgur.com/lsHGKTn.png', fill: '#0000FF' },
  hopwa: { table: CARTO.HOUSING_TABLE, imgSrc: 'https://i.imgur.com/eV6c82h.png', fill: '#0000FF' },
  
  hptn: { table: CARTO.TABLE, imgSrc: 'https://i.imgur.com/WMZ7jdL.png', fill: '#0000FF', condition: "hptn = 'X'" },
  mtn: { table: CARTO.TABLE, imgSrc: 'https://i.imgur.com/yNkXBVS.png', fill: '#0000FF', condition: "mtn = 'X'" },
  hvtn: { table: CARTO.TABLE, imgSrc: 'https://i.imgur.com/fMK8N0x.png', fill: '#0000FF', condition: "hvtn ='X'" },
  actg: { table: CARTO.TABLE, imgSrc: 'https://i.imgur.com/dwufsH3.png', fill: '#0000FF', condition: "actg = 'X'" },
  impaact: { table: CARTO.TABLE, imgSrc: 'https://i.imgur.com/sXCNHEX.png', fill: '#0000FF', condition: "impaact = 'X'" },
}

const conjunctionOfLayers = (keys, imgSrc) => {
  return {
    table: CARTO.TABLE,
    fill: '#0000FF',
    imgSrc: imgSrc,
    condition: keys.map(k => options[k].condition).join(' and ')
  }
}

export const addLayers = (serviceModel, mapconfig, markerSize) => {
  let configs = [];
  Object.keys(serviceModel).forEach(key => {
    if (serviceModel[key] && options[key]) {
      let config = options[key];
      configs.push(config);
    }
  })
  //Out of two
  if (serviceModel.hptn && serviceModel.mtn) {
    configs.push(conjunctionOfLayers(['hptn', 'mtn'], 'https://i.imgur.com/k4pt1N2.png'))
  }
  if (serviceModel.hptn && serviceModel.hvtn) {
    configs.push(conjunctionOfLayers(['hptn', 'hvtn'], 'https://i.imgur.com/X1Jtbbg.png'));
  }
  if (serviceModel.hptn && serviceModel.actg) {
    configs.push(conjunctionOfLayers(['hptn', 'actg'], 'https://i.imgur.com/90o3LoR.png'));
  }
  if (serviceModel.hptn && serviceModel.impaact) {
    configs.push(conjunctionOfLayers(['hptn', 'impaact'], 'https://i.imgur.com/EYQklKe.png'));
  }
  if (serviceModel.mtn && serviceModel.hvtn) {
    configs.push(conjunctionOfLayers(['mtn', 'hvtn'], 'https://i.imgur.com/8Huc02z.png'));
  }
  if (serviceModel.mtn && serviceModel.actg) {
    configs.push(conjunctionOfLayers(['mtn', 'actg'], 'https://i.imgur.com/QaaNaUo.png'));
  }
  if (serviceModel.mtn && serviceModel.impaact) {
    configs.push(conjunctionOfLayers(['mtn', 'impaact'], 'https://i.imgur.com/ZF6KUjT.png'));
  }
  if (serviceModel.hvtn && serviceModel.actg) {
    configs.push(conjunctionOfLayers(['hvtn', 'actg'], 'https://i.imgur.com/XyyFpoz.png'));
  }
  if (serviceModel.hvtn && serviceModel.impaact) {
    configs.push(conjunctionOfLayers(['hvtn', 'impaact'], 'https://i.imgur.com/2Paf2Z4.png'));
  }
  if (serviceModel.actg && serviceModel.impaact) {
    configs.push(conjunctionOfLayers(['actg', 'impaact'], 'https://i.imgur.com/e6PAeyg.png'));
  }
  //Out of three
  if (serviceModel.hptn && serviceModel.mtn && serviceModel.hvtn) {
    configs.push(conjunctionOfLayers(['hptn', 'mtn', 'hvtn'], 'https://i.imgur.com/gb8UK8M.png'));
  }
  if (serviceModel.hptn && serviceModel.mtn && serviceModel.actg) {
    configs.push(conjunctionOfLayers(['hptn', 'mtn', 'actg'], 'https://i.imgur.com/RX9imou.png'));
  }
  if (serviceModel.hptn && serviceModel.mtn && serviceModel.impaact) {
    configs.push(conjunctionOfLayers(['hptn', 'mtn', 'impaact'], 'https://i.imgur.com/SaTb4kE.png'));
  }
  if (serviceModel.hptn && serviceModel.hvtn && serviceModel.actg) {
    configs.push(conjunctionOfLayers(['hptn', 'hvtn', 'actg'], 'https://i.imgur.com/TCdzaZO.png'));
  }
  if (serviceModel.hptn && serviceModel.hvtn && serviceModel.impaact) {
    configs.push(conjunctionOfLayers(['hptn', 'hvtn', 'impaact'], 'https://i.imgur.com/IA2llQT.png'));
  }
  if (serviceModel.hptn && serviceModel.actg && serviceModel.impaact) {
    configs.push(conjunctionOfLayers(['hptn', 'hvtn', 'impaact'], 'https://i.imgur.com/C9DVRU1.png'));
  }
  if (serviceModel.mtn && serviceModel.hvtn && serviceModel.actg) {
    configs.push(conjunctionOfLayers(['mtn', 'hvtn', 'actg'], 'https://i.imgur.com/nXyv7C5.png'));
  }
  if (serviceModel.mtn && serviceModel.hvtn && serviceModel.impaact) {
    configs.push(conjunctionOfLayers(['mtn', 'hvtn', 'impaact'], 'https://i.imgur.com/ryPofR8.png'));
  }
  if (serviceModel.mtn && serviceModel.actg && serviceModel.impaact) {
    configs.push(conjunctionOfLayers(['mtn', 'actg', 'impaact'], 'https://i.imgur.com/paXCE0m.png'));
  }
  if (serviceModel.hvtn && serviceModel.actg && serviceModel.impaact) {
    configs.push(conjunctionOfLayers(['hvtn', 'actg', 'impaact'], 'https://i.imgur.com/lHfpPia.png'));
  }
  //Out of four
  if (serviceModel.hptn && serviceModel.mtn && serviceModel.hvtn && serviceModel.actg) {
    configs.push(conjunctionOfLayers(['hptn', 'mtn', 'hvtn', 'actg'], 'https://i.imgur.com/Z5ujPTW.png'));
  }
  if (serviceModel.hptn && serviceModel.mtn && serviceModel.hvtn && serviceModel.impaact) {
    configs.push(conjunctionOfLayers(['hptn', 'mtn', 'hvtn', 'impaact'], 'https://i.imgur.com/Pb5gJMr.png'));
  }
  if (serviceModel.mtn && serviceModel.hvtn && serviceModel.actg && serviceModel.impaact) {
    configs.push(conjunctionOfLayers(['mtn', 'hvtn', 'actg', 'impaact'], 'https://i.imgur.com/rz9YfUP.png'));
  }

  configs.forEach(config => {
    let sql = `SELECT * FROM ${config.table}`;
    if (config.condition) {
      sql += ` where ${config.condition}`
    }
    mapconfig.layers.push({
      type: 'cartodb', 
      options: {
        cartocss_version: '2.1.1',
        sql: sql,
        cartocss: `#layer { 
          marker-file: url(${config.imgSrc});
          marker-width: ${markerSize}; 
          marker-fill: ${config.fill};
          marker-fill-opacity: 0.9;
          marker-line-color: #FFF;
          marker-line-width: 1;
          marker-line-opacity: 1;
          marker-placement: point;
          marker-type: ellipse;
          marker-allow-overlap: true;
        } `
      }
    });
  })
}
