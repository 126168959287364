import React, { useState } from 'react';

import DeckGL from '@deck.gl/react';
import PopupStatic from './PopupStatic';
import { WebMercatorViewport } from 'react-map-gl';

const DeckGLWrapper = ({ id, width, height, style, initialViewState, controller, layers, options, stateValues,
  onViewStateChange,
  popupsData,
  countyValues,
  cityValues,
  stateCodes, children,
  hovered,
  hoveredMap, setHoveredMap,
  object, setObject,
  hideObject, setHideObject,
  keep, setKeep,
  popupList, setPopupList,
  stateComparison, countyComparison, cityComparison,
  comparisonOptions,
  order,
  isDraw
}) => {
  const [locked, setLocked] = useState(false);

  const projectObject = (viewport, o) => {
    if (o && o.lngLat) {
      let [x, y] = viewport.project([o.lngLat[0], o.lngLat[1]]);
      return { ...o, x: parseInt(x), y: parseInt(y) };
    } else {
      return o;
    }
  }

  const insertPopup = (data) => {
    lockerFn();
    setPopupList([...popupList, data])
    setObject(null);
    setKeep(false);
  }
  const removeExistant = (data) => {
    lockerFn();
    let idx = popupList.indexOf(data);
    popupList.splice(idx, 1);
    setPopupList(popupList)
  }
  const closePopup = () => {
    lockerFn();
    setObject(null);
    setKeep(false);
  }

  const getBoundsOnChange = ({viewState}) => {
    const viewport = new WebMercatorViewport(viewState);
    let x = popupList.map((pe) => {
      return projectObject(viewport, pe)
    })
    setPopupList(x);
    if(object) {
      let pobj = projectObject(viewport, object);
      setObject(pobj);
    }
    onViewStateChange({viewState})
  }

  const onClick = (e) => {
    if (locked || isDraw) return;
    if (object && object.object && object.object.properties && e.object && e.object.properties && object.layer.id != 'centroids' && object.layer.id != 'geojson-layer'  ) {
      if (object.object.properties.abbrev === e.object.properties.abbrev) {
        setKeep(true);
      }
    }
    if (!hovered) {
      setObject(hideObject)
    }
  }

  /**
   * This function is supposed to block unintentional click event firing on map when clicking on popup x or pin buttons
   */
  const lockerFn = () => {
    setLocked(true);
    setTimeout(() => {
      setLocked(false);
    }, 300)
  }

  return (
    <DeckGL id={id} width={width} height={height}
      style={style} initialViewState={initialViewState}
      controller={controller} layers={layers}
      getTooltip={x => {
        if(x && x.object && x.object.properties && x.object.properties.cluster == true ){
          return;
        }
        if ( x.layer && x.layer.id.startsWith('cluster') ) {
          setHideObject(x);
          return;
        }
        if (!keep && !hovered && popupList.length === 0) {
          setObject(x)
        }
        setHideObject(x);
      }}
      onClick={onClick}
      onViewStateChange= {getBoundsOnChange}
      >
      {
        children
      }
      {
        object && object.object && object.layer.id != 'geojson-layer'  &&
        <PopupStatic 
        insertPopup={insertPopup} close={closePopup} setHoveredMap={setHoveredMap} hoveredMap={hoveredMap}
        data={object} keep={keep} options={options} stateValues={stateValues} countyValues={countyValues} cityValues={cityValues} stateCodes={stateCodes} popupsData={popupsData} order={order} stateComparison={stateComparison} countyComparison={countyComparison} cityComparison={cityComparison} comparisonOptions={comparisonOptions}
        />
      }
      {
          popupList.map((popup, i) => {
            return (
              <PopupStatic key={'popup-' + i} data={popup} keep={true} close={removeExistant}
              setHoveredMap={setHoveredMap} hoveredMap={hoveredMap}
              options={options} popupsData={popupsData} stateValues={stateValues} countyValues={countyValues} cityValues={cityValues} stateCodes={stateCodes}  order={order} stateComparison={stateComparison} countyComparison={countyComparison} cityComparison={cityComparison} comparisonOptions={comparisonOptions}/>
            )
          })
        }
      
    </DeckGL>
  )

}

export default React.memo(DeckGLWrapper);