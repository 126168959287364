import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux'
import { Button, ButtonGroup } from 'reactstrap';

const OptionComparison = ({label, value, selected, selectID, disabled}) => {
  return (
    <label className={"controlbox controlbox_fontsize controlbox--radio " + (disabled?'disabled':'')} id={"select-comparison"+value} onClick={() => selectID(value,label)}>
      <input type="radio" name="customRadio" id={"select-comparison-input"+value} checked={selected == value}/>
      <div className="controlbox__indicator" id={"select-comparison-label"+value}></div>
        {label}
    </label> 
  )
}



export default OptionComparison;
