import {CompositeLayer} from '@deck.gl/core';
import {IconLayer, TextLayer} from '@deck.gl/layers';
import Supercluster from 'supercluster';

let markerdef = '00000';
function getIconName(d) {
  if (d.properties.cluster) {
    // Clustered icon
    return markerdef;
  } else {
    // Not clustered icon
    return d.properties.marker;
    
    // !!! Here you can return different icon mappings depending on other different properties
  }
}

function getIconSize(size) {
  if(size == 1 ){ 
    return 0.55;
  } else {
    return Math.min(100, size) / 200 + 1;
  }
  
}

function getPosition(d) {
  return d.geometry.coordinates
}

export default class IconClusterLayer extends CompositeLayer {
  shouldUpdateState({changeFlags}) {
    return changeFlags.somethingChanged;
  }

  updateState({props, oldProps, changeFlags}) {
    const rebuildIndex = changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;

    if (rebuildIndex) {
      const index = new Supercluster({maxZoom: 12, radius: props.sizeScale, minZoom:2});
      index.load(
        props.data.map(d => ({
          geometry: {coordinates: props.getPosition(d)},
          properties: d
        }))
      );
      this.setState({index});
    }

    const z = Math.floor(this.context.viewport.zoom);
    if (rebuildIndex || z !== this.state.z) {
      this.setState({
        data: this.state.index.getClusters([-180, -85, 180, 85], z),
        z
      });
    }
  }

  renderLayers() {
    const {data} = this.state;
    const {iconAtlas, iconMapping, sizeScale, markerDef} = this.props;
    markerdef = markerDef;
    return [
      new IconLayer(
        this.getSubLayerProps({
          id: 'icon' + Math.random(),
          data,
          iconAtlas,
          iconMapping,
          sizeScale,
          getPosition: d => getPosition(d),
          getIcon: d => getIconName(d),
          getSize: d => getIconSize(d.properties.cluster ? d.properties.point_count : 1),
          getColor: [109, 147, 226],
          updateTriggers: {
            getPosition: this.props.updateTriggers.getPosition,
            getSize: this.props.updateTriggers.getTextSize
          }
        })
      ),
      new TextLayer(
        this.getSubLayerProps({
          id: 'label'+ Math.random(),
          data,
          getPosition: d => getPosition(d),
          getText: d => d.properties.cluster ? d.properties.point_count.toString() : "",
          getSize: d => getIconSize(d.properties.cluster ? d.properties.point_count : 1) * 16,
          updateTriggers: {
            getPosition: this.props.updateTriggers.getPosition,
            getText: this.props.updateTriggers.getText,
            getSize: this.props.updateTriggers.getTextSize
          }
        })
      )
    ];
  }
}