const initialState = {
  isCollapsed: false
}

const arrowReducer = (state = initialState , action) => {
  switch(action.type) {
    case 'CHANGE_ARROW':
      return {
        ...state,
        ['isCollapsed']:action.value
      }
    default: 
      return state;
  }
}

export default arrowReducer;
