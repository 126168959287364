const initialState = {
  hivTesting: false,
  prep: false,
  hivRyanwhite: false,
  hopwa: false,
  hptnMtn: false,
  hptn: false,
  mtn: false,
  hvtn: false,
  actgImpaact: false,
  actg: false,
  impaact: false,
  hcs: false,
  isDistricts: false
};
const overlaysReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'CHANGE_SERVICE':
      return {
        ...state,
        [action.key]: action.value
      }
    default: 
      return state;
  }
}

export default overlaysReducer;