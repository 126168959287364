const initialState = { 
  countyData: [], 
  stateData: [], 
  cityData: []
 };
  

const downloadReducer = (state = initialState , action) => {
  switch(action.type) {
    case 'UPDATE_DOWNLOAD_VALUES':
      return {
        ...state,
        [action.key]: action.value
      }
    default: 
      return state;
  }
}

export default downloadReducer;
