const initialState = {
  _loading: 0,
  _loaded: 0
}

const loaderReducer = (state = initialState, action) => {
  switch(action.type) {
    case 'START_LOADER':
      return {
        ...state, 
        _loading: state._loading + 1 
      }
    case 'END_LOADER':
      let valueLoaded = 0;
      if (state._loaded < state._loading) {
        valueLoaded = 1;
      }
      return {
        ...state, 
        _loaded: state._loaded + valueLoaded 
      }
    
    default: 
      return state;
  }
}

export default loaderReducer;