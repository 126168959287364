const initialState = { 
  isOn: false
 };
  

const drawReducer = (state = initialState , action) => {
  switch(action.type) {
    case 'CHANGE_DRAW':
      return {
        ...state,
        isOn: !state.isOn
      }
    case 'END_DRAW': 
      return {
        ...state, 
        isOn: false
      }
    default: 
      return state;
  }
}

export default drawReducer;
